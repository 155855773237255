/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React, {useState} from "react";
import {Card} from "@mui/material";
import {Form, Input, Button} from "antd";
import "./contactUsForm.css"
import {MDBIcon} from "mdb-react-ui-kit";

const ContactUsForm = (props) => {

    const queryParameters = new URLSearchParams(window.location.search)
    const productName = queryParameters.get("productName")

    const layout = {
        labelCol: {span: 6},
        wrapperCol: { span: 16 },
    };
    const [state, setState] = useState(() => {
        if (productName) {
            return {name: "", email: "", message: "Hi, \n I am interested in " + productName + ".\n"};
        } else {
            return {name: "", email: "", message: ""};
        }
    });

    const handleChange = e => {
        const {name, value} = e.target;
        setState(prevState => ({
            ...prevState, [name]: value
        }));
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    return (
        <Card className="contactUsFormContainer">
            <h3 className="contactUsFormTitle">Send us a Message!</h3>
            <Form {...layout} validateMessages={validateMessages} style={{ maxWidth: 600 }}
                  onFinish={() => props.onFormSubmit(state)}>
                <Form.Item name={['name']} label="Your Name: " rules={[{required: true}]}>
                    <Input value={state.name} defaultValue={state.name} onChange={handleChange} name="name" style={{height: "40px"}}/>
                </Form.Item>
                <Form.Item name={['email']} label="Email: " rules={[{type: 'email', required: true}]}>
                    <Input value={state.email} defaultValue={state.email} onChange={handleChange} name="email" style={{height: "40px"}}/>
                </Form.Item>
                <Form.Item name={['message']} label="Message: " rules={[{required: true}]}>
                    <Input.TextArea value={state.message} defaultValue={state.message} onChange={handleChange} name="message" style={{height: "250px"}}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block className="contactUsFormBtn">
                        SEND&emsp;<MDBIcon fas icon="paper-plane" size='lg'/>
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default ContactUsForm;