/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./ContactUs.css";
import ContactUsMainContent from "../../components/contactUsMainContent/ContactUsMainContent";
import NotificationBar from "../../components/notificationBar/NotificationBar";

const ContactUs = (props) => {
    return (
        <div className="contactUs">
            <NotificationBar/>
            <Header theme={props.theme}/>
            <ContactUsMainContent theme={props.theme} />
            <Footer/>
        </div>);
}

export default ContactUs;