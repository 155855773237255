/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import {image as carouselImage} from "../../data/HomePageCarouselImages";
import {products} from "../../data/BestSellersContent";
import CarouselContainer from "../carousel/CarouselContainer";
import BestSellers from "../bestSellers/BestSellers";
import AboutUs from "../aboutUs/AboutUs";
import "./HomePageMainContent.css"
import WhyChooseUs from "../whyChooseUs/WhyChooseUs";
import CustomizationProcess from "../customizationProcess/CustomizationProcess";

const HomePageMainContent = (props) => {
    return (
        <div className="contentContainer">
            <CarouselContainer data={carouselImage} theme={props.theme} />
            <BestSellers data={products} theme={props.theme} />
            <AboutUs />
            <WhyChooseUs />
            <CustomizationProcess />
        </div>
    );
}

export default HomePageMainContent;