import React from "react";
import './App.css';
/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./views/Home/Home";
import Products from "./views/Products/Products";
import ContactUs from "./views/ContactUs/ContactUs";
import {chosenTheme} from "./theme";

function App() {
    return (<BrowserRouter>
            <Routes>
                <Route path="/" element={<Home theme={chosenTheme}/>} />
                <Route exact path="/home" element={<Home theme={chosenTheme}/>}/>
                <Route exact path="/products" element={<Products theme={chosenTheme}/>}/>
                <Route exact path="/contact" element={<ContactUs theme={chosenTheme}/>}/>
            </Routes>
        </BrowserRouter>);
}

export default App;
