/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import "./AboutUs.css";
import title from "../../assets/aboutUsTitle.png";
import {Grid} from "@mui/material";
import image1 from "../../assets/aboutUsImage1.jpeg"
import image2 from "../../assets/aboutUsImage2.jpeg"
import image3 from "../../assets/aboutUsImage3.jpeg"
import image4 from "../../assets/aboutUsImage4.jpeg"

const AboutUs = () => {
    return (
        <div className='aboutUsContainer'>
            <img src={title} alt="" className="aboutUsTitle"/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} className="aboutUsContentContainer">
                    <p>
                        Achacha LLC is a sophisticated company that specializes in the design, production, and sales of
                        refrigeration equipment. Our headquarters is located in Wyoming, USA, and we utilize cutting-edge
                        technology from around the world to meet the unique needs of our diverse clientele. Our range of
                        top-quality products includes box-type and open-type condensing units, various types of air coolers and
                        fluid coolers, industrial chillers, heat exchangers, evaporators, and tube-fin heat exchangers, among
                        others. Our team is committed to upholding rigorous quality control standards and providing exceptional
                        customer service. Our experienced staff members are always available to discuss your requirements and
                        ensure that you are completely satisfied with your purchase.
                    </p>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} rowSpacing={4} columnSpacing={1}>
                    <Grid item xs={12} sm={12} md={6} className="aboutUsImageContainer">
                        <img src={image1} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="aboutUsImageContainer">
                        <img src={image2} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="aboutUsImageContainer">
                        <img src={image3} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="aboutUsImageContainer">
                        <img src={image4} alt="" />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default AboutUs;