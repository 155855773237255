import React from "react";
import LogoSvg from "../../assets/logo.svg";
import "./Logo.css"

const Logo = () => {
    return (
        <div className="logoContainer">
            <img src={LogoSvg} alt="Achacha LLC Copyright" id="logoContent"/>
        </div>
    );
};
export default Logo;