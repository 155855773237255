/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React, {useState} from "react";
import "./ContactUsMainContent.css"
import ContactUsForm from "../contactUsForm/contactUsForm";
import {postCustomerEmail} from "../../api/postCustomerEmail";
import ContactUsSuccessMessage from "../contactUsSuccessMessage/contactUsSuccessMessage";
import ContactUsErrorMessage from "../contactUsErrorMessage/contactUsErrorMessage";

const ContactUsMainContent = (props) => {
    const [view, setView] = useState("ContactUsForm");

    const SUCCESS_CODE = 200;

    const onFormSubmit = async (state) => {
        let data = {
            name: state.name,
            email: state.email,
            message: state.message
        }
        let response = await postCustomerEmail(data);
        if (SUCCESS_CODE === response) {
            setView("ContactUsSuccessMessage");
        } else {
            setView("ContactUsErrorMessage");
        }
    };

    const onSuccessMessageClick = () => {
        setView("ContactUsForm");
    }

    const onErrorMessageClick = () => {
        setView("ContactUsForm");
    }

    return (
        <div className="contentContainer">
            {view === "ContactUsForm" ? <ContactUsForm onFormSubmit={onFormSubmit} />
                : (view === "ContactUsSuccessMessage" ?
                    <ContactUsSuccessMessage onSuccessMessageClick={onSuccessMessageClick} />
                    : <ContactUsErrorMessage onErrorMessageClick={onErrorMessageClick} />)
            }
        </div>
    );
}

export default ContactUsMainContent;