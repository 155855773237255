/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import "./ProductsSider.css";
import { Layout, Menu } from 'antd';
const { Sider } = Layout;

const ProductsSider = (props) => {
    const items: MenuProps['items'] = ["Cold Room Equipment", "Chiller", "Unit Cooler", "Heat Exchanger"].map(
        (value, index) => {
            const key = String(index + 1);
            switch (value) {
                case "Cold Room Equipment":
                    return {
                        key: `sub${key}`,
                        label: value,
                        children: ["Air Cooler / Evaporator", "Condensing Unit"].map((subValue, subIndex) => {
                            return {
                              key: subValue.replaceAll(" ", "_"),
                              label: subValue,
                            };
                        }),
                    };
                case "Chiller":
                    return {
                        key: `sub${key}`,
                        label: value,
                        children: ["Air Cooled Chiller", "Water Cooled Chiller"].map((subValue, subIndex) => {
                            return {
                              key: subValue.replaceAll(" ", "_"),
                              label: subValue,
                            };
                        }),
                    };
                case "Unit Cooler":
                    return {
                        key: value.replaceAll(" ", "_"),
                        label: value
                    };
                case "Heat Exchanger":
                    return {
                        key: `sub${key}`,
                        label: value,
                        children: ["Tube-fin Heat Exchanger", "Plate Heat Exchanger"].map((subValue, subIndex) => {
                            return {
                              key: subValue.replaceAll(" ", "_"),
                              label: subValue,
                            };
                        }),
                    };
                default:
                    return {};
            }
          }
    );

  const linkTo = (e) => {
    const element = document.getElementById(e.key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: "start", inline: "start"});
    }
  };

    return (
        <Sider width={300} className="productSiderContainer">
            <Menu
            mode="inline"
            defaultSelectedKeys={['sub1']}
            defaultOpenKeys={['sub1', 'sub2', 'sub4']}
            style={{ height: '100%', borderRight: 0 }}
            items={items}
            onClick={e => linkTo(e)}
        />
        </Sider>
    );
}

export default ProductsSider;