/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

const products = [
    {
       avatar: {
            url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/NPI+Industrial+Type+Air+Cooler.png",
            description : "NPI Industrial Evaporator"
       },
       details: {
            imageUrls: [],
            features: [
                "High efficiency staggered fin pattern design",
                "Special sine wave fin design for better heat transfer",
                "Internal grooved copper tube for higher heat exchanger efficiency",
                "Specific fan motor for low temp, application, high air volume & air throw",
                "Optimized air flow for unit cooler face area to reach maximum capacity",
                "Optimized circuiting design for refrigerant fully evaporating",
                "Imported brand e-heaters applied for e-defrost type",
                "Casing with white coating for corrosion protection",
                "Robust, state-of-the-art sheet metal design and ss casing is available",
                "Easy moving design of side cover for easy maintenance",
                "4-7mm fin Spacing optional for different application",
                "Dual fin spacing design is available for minimize frost",
                "Specific unit cooler is available for special application",
                "Dual drain tray design to avoid water condensation",
                "Hot gas defrost is available for coils of some models",
                "Distributor support to avoid unexpected vibration",
                "Special \"Floating Tube\" design for large unit cooler",
                "Air jet for longer air throw is available for certain models Robust, state-of-the-art sheet metal design and SS casing is available",
            ],
            useCase: [
                                    "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                                    "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                                    "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                                    "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                                    "Greenhouse, flowers, poultry, livestock and other types of farms",
                                    "The existing air conditioning and blower equipment modification and installation",
                                    "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                                    "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                                ],
            parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/NPC+NPI+Series_watermark.pdf"
       }
    },
    {
       avatar: {
            url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/Model+U+CUC+Box-Type+Unit.jpg",
            description : "Model U CUC Box-type Unit"
       },
       details: {
            imageUrls: [],
            features: [
                "Reasonable design, be applicable to the outdoor installation",
                "Third generation top wind series"
            ],
            useCase: [
                "Apply to all kinds of high temperature of the cold storage",
                "Display ark storage",
                "Industrial equipment storage"
            ],
            parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Box-Type+Unit_watermark.pdf"
       }
    },
    {
       avatar: {
            url: "https://d32u2qmsgdr7i7.cloudfront.net/unitCooler/V-shaped+fluid+cooler.png",
            description : "V-shaped Fluid Cooler"
       },
       details: {
            imageUrls: [],
            features:[
                "Unique floating coil design avoids leakage caused by thermal expansion and contraction",
                "Staggered fin pattern design and corrugated fin enhance air flow turbulence",
                "Inner-grooved copper tube increase heat transfer area and enhance heat transfer",
                "Optimized flow pass design ensure fully heat exchange with refrigerant",
                "Equipped with 2nd generation owl-wing fan motor as standard. It features with low noise level, large volume flow and low energy consumption. Ready for dual speed connection",
                "Outdoor type powder coated casing made of galvanized sheet metal and stainless steel bolts provide a high resistance to corrosion",
                "EC variable speed fan motor, to save great energy",
                "ZAplus/AxiTop for greater volume flow and extreme silence",
                "Adiabatic system significantly reduce energy consumption in summer",
                "Epoxy coated or hydrophilic aluminum fin suitable for coastal or high corrosion area",
                "Clean stainless steel tube and aluminum fin coil for cooling deionized water",
            ],
            useCase: [
                "Process engineering",
                "Heat / power plant",
                "Power generation",
                "Chemical industry",
                "Air conditioning chiller",
                "Heat transformer",
                "Smelting",
                "And many more..."
            ],
            parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DRY+COOLER+SHSL-D2+SERIES_watermark.pdf"
       }
    },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Stainless+Steel+Tube+Heat+Exchanger.jpeg",
                        description : "Stainless Steel Tube Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Stainless steel tube heat exchanger can be used in food industry and medical area"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
]

export {
    products
};