/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React, {Component} from "react";
import "./Header.css";
import {NavLink, Link} from "react-router-dom";
import SeoHeader from "../seoHeader/SeoHeader";
import Logo from "../logo/Logo";

const onMouseEnter = (event, color) => {
    const el = event.target;
    el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
    const el = event.target;
    el.style.backgroundColor = "transparent";
};

class Header extends Component {
    render() {
        const theme = this.props.theme;
        const defaultLink = "/home";
        return (
            <div>
                <SeoHeader/>
                <div>
                    <header className="header">
                        <NavLink to={defaultLink} tag={Link} className="logo">
                            <Logo/>
                        </NavLink>
                        <input className="menu-btn" type="checkbox" id="menu-btn"/>
                        <label className="menu-icon" htmlFor="menu-btn">
                            <span className="navicon"></span>
                        </label>
                        <ul className="menu" style={{backgroundColor: theme.body}}>
                            <li>
                                <NavLink
                                    to="/home"
                                    tag={Link}
                                    activeStyle={{fontWeight: "bold"}}
                                    style={{color: theme.text}}
                                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                                    onMouseOut={(event) => onMouseOut(event)}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/products"
                                    tag={Link}
                                    activeStyle={{fontWeight: "bold"}}
                                    style={{color: theme.text}}
                                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                                    onMouseOut={(event) => onMouseOut(event)}
                                >
                                    Products
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/contact"
                                    tag={Link}
                                    activeStyle={{fontWeight: "bold"}}
                                    style={{color: theme.text}}
                                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                                    onMouseOut={(event) => onMouseOut(event)}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
{
//TODO: Enable Search function
/*                            <li>
                                <form className="d-none d-md-flex input-group w-auto my-auto">
                                    <input id="mdb-5-search-input" autoComplete="off" type="search"
                                           className="form-control rounded"
                                           placeholder="Keywords..."/>
                                    <span className="input-group-text border-0">
                                            <i className="fas fa-search"
                                               id="mdb-5-search-icon"></i>
                                        </span>
                                </form>
                            </li>
*/}
                        </ul>
                    </header>
                </div>
            </div>
        );
    }
}

export default Header;