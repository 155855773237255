/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from 'react';
import {Carousel} from 'antd';
import "./CarouselContainer.css";

const CarouselContainer = (props) => (
    <Carousel autoplay className='carouselContainer'>
        {props.data.map((image) => (
            <div>
                <img src={image.url} alt={image.description} />
            </div>
        ))}
    </Carousel>
);

export default CarouselContainer;