/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import title from "../../assets/whyChooseUsTitle.png";
import Badge from "../badge/Badge";
import sincerityIcon from "../../assets/sincerity.png"
import serviceIcon from "../../assets/service.png"
import productionIcon from "../../assets/production.png"
import qualityIcon from "../../assets/quality.png"
import customerServiceIcon from "../../assets/customer-service.png"
import logisticsIcon from "../../assets/logistics.png"

import {Grid} from "@mui/material";

const WhyChooseUs = () => {
    return (
        <div className='aboutUsContainer'>
            <img src={title} alt="" className="aboutUsTitle"/>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} key={1}>
                    <Badge image={productionIcon} title="PRODUCTION" content="We oversee the production process of every project by directing and collaborating with our team, both in the factory and at other production facilities. We take a hands-on approach to ensure that every aspect of production meets our high standards of quality and efficiency."/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} key={2}>
                    <Badge image={serviceIcon} title="SERVICE" content="Our service is backed by cutting-edge selection and design software, which has been customized specifically for our needs by the original equipment manufacturer (OEM). Our team of professionals utilize this software to ensure that our service meets the highest standards of quality and precision." />
                </Grid>
                <Grid item xs={12} sm={12} md={6} key={3}>
                    <Badge image={qualityIcon} title="QUALITY" content="We take quality seriously, and as such, we hold both UL certification and ISO 9001 accreditation. Our commitment to quality is further demonstrated by our use of professional laboratories and testing facilities, where our products undergo rigorous testing and analysis to ensure that they meet the highest standards of performance and reliability." />
                </Grid>
                <Grid item xs={12} sm={12} md={6} key={4}>
                    <Badge image={customerServiceIcon} title="CUSTOMER SERVICE" content="At our company, we place a high value on customer service, and we take every customer inquiry seriously. We make it our priority to respond to all questions and requests within 10 hours, and we encourage our customers to reach out to us with any concerns or inquiries they may have. Please don't hesitate to contact us for assistance." />
                </Grid>
                <Grid item xs={12} sm={12} md={6} key={5}>
                    <Badge image={logisticsIcon} title="LOGISTICS" content="We use expert logistics team to handle all aspects of transportation, ensuring that our products are delivered on time and in top condition. From coordinating shipments to managing customs clearance and documentation, the logistics team is dedicated to providing reliable and efficient services that meet the highest standards of quality and professionalism." />
                </Grid>
                <Grid item xs={12} sm={12} md={6} key={6}>
                    <Badge image={sincerityIcon} title="SINCERITY" content="Our values are centered on sincerity - a deep commitment to prioritizing our customers and providing them with exceptional professional services. We continuously strive to put our customers at the forefront of all our operations and deliver top-tier services that exceed their expectations." />
                </Grid>
            </Grid>
        </div>
    )
}

export default WhyChooseUs;