/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import {CardMedia, CardContent, Typography} from "@mui/material";
import "./Badge.css"
const Badge = (props) => {
    return (
        <div className='badgeContainer'>
            <CardMedia
                component="img"
                image= {props.image}
                alt= ""
            />
            <CardContent className='badgeContent'>
                <h5>{props.title}</h5>
                <Typography>
                    {props.content}
                </Typography>
            </CardContent>
        </div>
    );
}
export default Badge;