/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import {Card} from "@mui/material";
import "./contactUsErrorMessage.css";

const ContactUsErrorMessage = (props) => {
    return (
        <Card className="contactUsMessageContainer" onClick={props.onErrorMessageClick}>
            <div className="errorMessage">
                <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76"
                     className="errorMessageIcon" viewBox="0 0 16 16">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                        fill="#ff0000"></path>
                </svg>
                <h1 className="errorMessageTitle">Error Occurred</h1>
                <div className="errorMessageContent">
                    <p>Please send us an email at <a href="mailto:achachallc@gmail.com">achachallc@gmail.com</a>.
                        Sorry for the inconvenience.</p>
                </div>
            </div>
        </Card>
    );
}

export default ContactUsErrorMessage;