/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from 'react';
import axios from "axios";

const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'YWNoYWNoYWxsYzpmcm9udGVuZFVJ'
}
const sendEmailUrl = "https://qj72i8ksi9.execute-api.us-east-2.amazonaws.com/prod/sendEmail"
const INTERNAL_ERROR_CODE = 507;
export const postCustomerEmail = async data => {
    const body = JSON.stringify({
        subject: `Message from New Client ${data.name} - ${new Date().toUTCString()}`,
        content: {...data}
    });
    return axios.post(sendEmailUrl, body, {
        headers: headers,
        method: 'POST',
    })
        .then((response) => {
            return response.status;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.status
            } else {
                return INTERNAL_ERROR_CODE;
            }
        })
}