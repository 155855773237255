/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import "./CustomizationProcess.css";
import title from "../../assets/customizationTitle.png";
import inquiryIcon from "../../assets/email.png"
import contractIcon from "../../assets/contract.png"
import productionIcon from "../../assets/factory.png"
import qualityAssuranceIcon from "../../assets/quality-control.png"
import transportationIcon from "../../assets/transportation.png"
import helpDeskIcon from "../../assets/help-desk.png"

const CustomizationProcess = () => {
    return (
        <div className='customizationProcessContainer'>
            <img src={title} alt="" className="aboutUsTitle"/>
            <ol className="process-chart">
                <li>
                    <div>
                        <h4>Submit an Inquiry</h4>
                        <div className="customizationProcessIconContainer">
                            <img src={inquiryIcon} alt="" className="customizationProcessIcon"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <h4>Sign a Contract</h4>
                        <div className="customizationProcessIconContainer">
                            <img src={contractIcon} alt="" className="customizationProcessIcon"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <h4>Production</h4>
                        <div className="customizationProcessIconContainer">
                            <img src={productionIcon} alt="" className="customizationProcessIcon"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <h4>Quality Assurance</h4>
                        <div className="customizationProcessIconContainer">
                            <img src={qualityAssuranceIcon} alt="" className="customizationProcessIcon"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <h4>Transportation</h4>
                        <div className="customizationProcessIconContainer">
                            <img src={transportationIcon} alt="" className="customizationProcessIcon"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <h4>Customer Service</h4>
                            <div className="customizationProcessIconContainer">
                                <img src={helpDeskIcon} alt="" className="customizationProcessIcon"/>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    )
}

export default CustomizationProcess;