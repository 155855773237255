/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./Products.css";
import ProductsPageMainContent from "../../components/productsPageMainContent/ProductsPageMainContent";
import NotificationBar from "../../components/notificationBar/NotificationBar";

const Products = (props) => {
    return (
        <div className="productsPage">
            <NotificationBar/>
            <Header theme={props.theme} />
            <ProductsPageMainContent theme={props.theme} />
            <Footer />
        </div>
    );
}

export default Products;