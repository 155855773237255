/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./Home.css";
import HomePageMainContent from "../../components/homePageMainContent/HomePageMainContent";
import NotificationBar from "../../components/notificationBar/NotificationBar";

const Home = (props) => {
    return (
        <div className="homePage">
            <NotificationBar/>
            <Header theme={props.theme} />
            <HomePageMainContent theme={props.theme} />
            <Footer />
        </div>
    );
}

export default Home;