/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import "./NotificationBar.css"
import {MDBIcon} from "mdb-react-ui-kit";
import {footerTheme} from "../../theme";

const NotificationBar = (props) => {
    const theme = footerTheme;
    return (
        <div className="notificationBar" style={{backgroundColor: theme.body, color: theme.text}}>
            <span><a href="mailto:achachallc@gmail.com"><MDBIcon icon="envelope" className="me-3"/>achachallc@gmail.com</a></span>
        </div>
    );
}

export default NotificationBar;