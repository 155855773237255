/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import CardContainer from "../card/CardContainer";
import "./BestSellers.css"
import title from "../../assets/bestSellersTitle.png"
import { Col, Row } from 'antd';

const BestSellers = (props) => {
    return (
        <div className='bestSellersContainer'>
            <img src={title} alt="" className="bestSellersTitle"/>
            <Row align="middle">
                {props.data.map((product, index) => (
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6}}>
                        <CardContainer data={product} />
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default BestSellers;