/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import {Card} from "@mui/material";
import "./contactUsSuccessMessage.css"

const ContactUsSuccessMessage = (props) => {
    return (
        <Card className="contactUsMessageContainer" onClick={props.onSuccessMessageClick}>
            <div className="successMessage">
                <svg viewBox="0 0 76 76" className="successMessageIcon">
                    <circle cx="38" cy="38" r="36" fill="#3DC480"/>
                    <path fill="none" stroke="#FFFFFF" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"
                          stroke-miterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7"/>
                </svg>
                <h1 className="successMessageTitle">Message Received</h1>
                <div className="successMessageContent">
                    <p>We promise to respond in 24 hours</p>
                </div>
            </div>
        </Card>
    );
}

export default ContactUsSuccessMessage;