/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

const contents = [
    {
        title: "Air Cooler / Evaporator",
        products: [
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/NPC302D04.jpg",
                    description : "NPC Commercial Air Cooler"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Special sine wave fin design for better heat transfer",
                        "Internal grooved copper tube for higher heat exchanger efficiency",
                        "Specific fan motor for low temp, application, high air volume & air throw",
                        "Optimized air flow for unit cooler face area to reach maximum capacity",
                        "Optimized circuiting design for refrigerant fully evaporating",
                        "Imported brand e-heaters applied for e-defrost type",
                        "Casing with white coating for corrosion protection",
                        "Robust, state-of-the-art sheet metal design and ss casing is available",
                        "Easy moving design of side cover for easy maintenance",
                        "4-7mm fin Spacing optional for different application",
                        "Dual fin spacing design is available for minimize frost",
                        "Specific unit cooler is available for special application",
                        "Dual drain tray design to avoid water condensation",
                        "Hot gas defrost is available for coils of some models",
                        "Distributor support to avoid unexpected vibration",
                        "Special \"Floating Tube\" design for large unit cooler",
                        "Air jet for longer air throw is available for certain models Robust, state-of-the-art sheet metal design and SS casing is available",
                    ],
                    useCase: [
                                            "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                                            "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                                            "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                                            "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                                            "Greenhouse, flowers, poultry, livestock and other types of farms",
                                            "The existing air conditioning and blower equipment modification and installation",
                                            "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                                            "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                                        ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/NPC+NPI+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/NPI+Industrial+Type+Air+Cooler.png",
                    description : "NPI Industrial Evaporator"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Special sine wave fin design for better heat transfer",
                        "Internal grooved copper tube for higher heat exchanger efficiency",
                        "Specific fan motor for low temp, application, high air volume & air throw",
                        "Optimized air flow for unit cooler face area to reach maximum capacity",
                        "Optimized circuiting design for refrigerant fully evaporating",
                        "Imported brand e-heaters applied for e-defrost type",
                        "Casing with white coating for corrosion protection",
                        "Robust, state-of-the-art sheet metal design and ss casing is available",
                        "Easy moving design of side cover for easy maintenance",
                        "4-7mm fin Spacing optional for different application",
                        "Dual fin spacing design is available for minimize frost",
                        "Specific unit cooler is available for special application",
                        "Dual drain tray design to avoid water condensation",
                        "Hot gas defrost is available for coils of some models",
                        "Distributor support to avoid unexpected vibration",
                        "Special \"Floating Tube\" design for large unit cooler",
                        "Air jet for longer air throw is available for certain models Robust, state-of-the-art sheet metal design and SS casing is available",
                    ],
                    useCase: [
                                            "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                                            "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                                            "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                                            "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                                            "Greenhouse, flowers, poultry, livestock and other types of farms",
                                            "The existing air conditioning and blower equipment modification and installation",
                                            "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                                            "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                                        ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/NPC+NPI+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DJ40+(3+fans).JPG",
                    description : "Air Cooler DJ Series - 3 fans"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DJ70+(4+fans)+4.JPG",
                    description : "Air Cooler DJ Series - 4 fans"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DJ105+(5+fans).JPG",
                    description : "Air Cooler DJ Series - 5 fans"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DL80+(3+fans).JPG",
                    description : "Air Cooler DL Series - 3 fans"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DD+(1+fan).jpg",
                    description : "Air Cooler DD Series - 1 fan"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DD+(2+fans).jpg",
                    description : "Air Cooler DD Series - 2 fans"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooler/air+cooler+DD+(4+fans).jpg",
                    description : "Air Cooler DD Series - 4 fans"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "High efficiency staggered fin pattern design",
                        "Optimized sine waved fin for high performance",
                        "Internal grooved tube for higher heat exchanger efficiency",
                        "Optimized circuiting for better evaporation",
                        "Optimized air flow to reach max. Capacity",
                        "Robust，state-of-the-art sheet metal design",
                        "Casing with white coating for corrosion protection",
                        "Dual drip tray design to avoid water condensation",
                        "Fin spacing optional for specific application",
                        "Dual fin spacing design is available for min. Frost",
                        "Tailor made unit cooler is available for special use case",
                        "Specific fan motor for low temp. application, higher air flow, higher air throw",
                        "Optional defrost available (Electric, water & hot gas defrost)",
                        "Imported brand e-heaters applied for defrost",
                        "Distributor support design to avoid unexpected vibration",
                        "Special 'Floating Tube' design for large unit cooler",
                        "SS or Al casing is available for certain models",
                        "Air jet for longer air throw is available for certain models",
                        "Available for CO2-DX unit cooler up to 40 bar",
                        "Provide customized brine air cooler",
                    ],
                    useCase: [
                        "Textile, machinery, ceramics, fine chemicals, metallurgy, glass, metal, leather and other manufacturing industries",
                        "Electroplating, electronics, footwear, printing, plastics, clothing, printing, packaging, food processing industry",
                        "Hospitals, waiting rooms, schools, waiting rooms, supermarkets, laundry room",
                        "Kitchen, vegetable market, a large entertainment center, underground parking, railway stations and other public places",
                        "Greenhouse, flowers, poultry, livestock and other types of farms",
                        "The existing air conditioning and blower equipment modification and installation",
                        "The requirements of high humidity places (such as textiles, planting, etc.), can be part of the interior installation design",
                        "The requirements of low humidity, low humidity and high cleanliness of the place, please carefully consider the suitability of use",
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DL+DD+DJ+Series_watermark.pdf"
               }
            },
        ]
    },
    {
        title: "Condensing Unit",
        products: [
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/3HP+open+type+condensing+unit.jpg",
                    description : "Semi-hermetic Condensing Unit - 3HP"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "The compressor unit can for use in many kinds of refrigerants such as R22, R134a, R404, R407C",
                        "The same serial compressor unit, is suitable for different applications",
                        "Equipped with oil separator, high and lower pressure controller, solenoid valve, filter drier, high and lower pressure gauge, big volume receiver and high efficient condensing units, it is good match unit",
                        "Construction compact, equipped with protect cover, stability, service life long and good looking",
                        "Excellent design for the driving parts to reduced the vibration result",
                        "The reasonable suction device in order to compressor cylinder has completed cooling",
                        "According to different applications to equipment with high efficient W-pattern Rifle bored copper, it can be reduced overall dimension and add transfer-heating result",
                        "BFBV20 over 56H(Y) compressor unit adopted split wire motor, it can use small current to start running",
                        "The motor uses electronic module protector or advanced heat protector to control the temperature",
                        "BFBV20 over 84.5H(Y)compress unit or adopted oil different pressure controller to ensure well lubrication system and equipped with auto-proctor of high pressure release valve",
                        "Crankcase heater fitted to prevent liquid migration",
                    ],
                    useCase: ["Commercial Refrigeration", "Cold Room", "Retail Refrigeration Application", "And many more…",],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Semi-Hermetic+Compressor+Air-Cooled+Condensing+Unit%EF%BC%8830HP-50HP%EF%BC%89_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/4HP+open+type+condensing+unit.jpg",
                    description : "Semi-hermetic Condensing Unit - 4HP"
               },
               details: {
                                   imageUrls: [],
                                   features: [
                                       "The compressor unit can for use in many kinds of refrigerants such as R22, R134a, R404, R407C",
                                       "The same serial compressor unit, is suitable for different applications",
                                       "Equipped with oil separator, high and lower pressure controller, solenoid valve, filter drier, high and lower pressure gauge, big volume receiver and high efficient condensing units, it is good match unit",
                                       "Construction compact, equipped with protect cover, stability, service life long and good looking",
                                       "Excellent design for the driving parts to reduced the vibration result",
                                       "The reasonable suction device in order to compressor cylinder has completed cooling",
                                       "According to different applications to equipment with high efficient W-pattern Rifle bored copper, it can be reduced overall dimension and add transfer-heating result",
                                       "BFBV20 over 56H(Y) compressor unit adopted split wire motor, it can use small current to start running",
                                       "The motor uses electronic module protector or advanced heat protector to control the temperature",
                                       "BFBV20 over 84.5H(Y)compress unit or adopted oil different pressure controller to ensure well lubrication system and equipped with auto-proctor of high pressure release valve",
                                       "Crankcase heater fitted to prevent liquid migration",
                                   ],
                                   useCase: ["Commercial Refrigeration", "Cold Room", "Retail Refrigeration Application", "And many more…",],
                                   parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Semi-Hermetic+Compressor+Air-Cooled+Condensing+Unit%EF%BC%8830HP-50HP%EF%BC%89_watermark.pdf"
                              }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/30HP+open+type+condensing+unit.jpg",
                    description : "Semi-hermetic Condensing Unit - 30HP"
               },
               details: {
                                   imageUrls: [],
                                   features: [
                                       "The compressor unit can for use in many kinds of refrigerants such as R22, R134a, R404, R407C",
                                       "The same serial compressor unit, is suitable for different applications",
                                       "Equipped with oil separator, high and lower pressure controller, solenoid valve, filter drier, high and lower pressure gauge, big volume receiver and high efficient condensing units, it is good match unit",
                                       "Construction compact, equipped with protect cover, stability, service life long and good looking",
                                       "Excellent design for the driving parts to reduced the vibration result",
                                       "The reasonable suction device in order to compressor cylinder has completed cooling",
                                       "According to different applications to equipment with high efficient W-pattern Rifle bored copper, it can be reduced overall dimension and add transfer-heating result",
                                       "BFBV20 over 56H(Y) compressor unit adopted split wire motor, it can use small current to start running",
                                       "The motor uses electronic module protector or advanced heat protector to control the temperature",
                                       "BFBV20 over 84.5H(Y)compress unit or adopted oil different pressure controller to ensure well lubrication system and equipped with auto-proctor of high pressure release valve",
                                       "Crankcase heater fitted to prevent liquid migration",
                                   ],
                                   useCase: ["Commercial Refrigeration", "Cold Room", "Retail Refrigeration Application", "And many more…",],
                                   parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Semi-Hermetic+Compressor+Air-Cooled+Condensing+Unit%EF%BC%8830HP-50HP%EF%BC%89_watermark.pdf"
                              }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/Air-cooled+condensing+unit.jpg",
                    description : "Air-cooled Condensing Unit"
               },
               details: {
                    imageUrls: [],
                    features: ["Equipped with oil separator, high and lower pressure controller, solenoid valve, filter drier, high and lower pressure gauge, big volume receiver and highly efficient condensing units, it is good match unit",
                               "Construction compact, equipped with protect cover, stability, service life long and good looking",
                               "Excellent design for the driving parts to reduce the vibration result",
                               "The reasonable suction device in order to compressor cylinder has completed cooling",
                               "According to different applications to equipment with highly efficient W-pattern Rifle bored copper, it can be reduced overall dimension and add transfer-heating result",
                               "BFBV20 over 56H(Y) compressor unit adopted split wire motor, it can use small current to start running",
                               "The motor uses electronic module protector or advanced heat protector to control the temperature",
                               "BFBV20 over 84.5H(Y)compress unit or adopted oil different pressure controller to ensure well lubrication system and equipped with auto-proctor of high-pressure release valve",
                               "Crankcase heater fitted to prevent liquid migration",
                    ],
                    useCase: ["Commercial Refrigeration", "Cold Room", "Retail Refrigeration Application", "And many more…",],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Semi-Hermetic+Compressor+Air-Cooled+Condensing+Unit%EF%BC%8830HP-50HP%EF%BC%89_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/Cold+Room+Refrigeration+Unit.jpg",
                    description : "MT Series Cold Room Refrigeration Unit"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "Standard condenser heat exchange area to ensure condensing efficiency",
                        "Good quality axial fan and motor ensure air volume and heat exchange"
                    ],
                    useCase: ["Commercial Refrigeration", "Cold Room", "Retail Refrigeration Application", "And many more…",],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/MT+Series_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/box+type+condensing+unit+-single+vent.jpg",
                    description : "Bitzer Box-type Condensing Unit"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "Storage temperature: -20～-15 ℃",
                        "Power supply: 380V/3P/50Hz",
                        "Evaporation temperature: -23℃",
                        "Environment temperature: 35℃",
                        "Inhale overheat: 10℃",
                        "Control panel for icy room temperature and standard control panel (optional)",
                        "Automatic ON and OFF function",
                        "Automatic control function for defrosting",
                        "Multi-protection guaranteed (high low-pressure protection, oil presser protection, compressor module protection, temperature and over current protection)",
                        "Fans speed is controllable according to the changing temperature settings",
                        "Buzzer alarm in case of any failure while working",
                        "Easy to install and operate the control panel",
                        "Control panel displays alarm function, working status, and all temperature settings",
                    ],
                    useCase: ["Commercial Refrigeration", "Cold Room", "Retail Refrigeration Application", "And many more…",],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Bitzer+Box-type+condensing+unit_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/Model+U+CUC+Box-Type+Unit.jpg",
                    description : "Model U CUC Box-type Unit"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "Reasonable design, be applicable to the outdoor installation",
                        "Third generation top wind series"
                    ],
                    useCase: [
                        "Apply to all kinds of high temperature of the cold storage",
                        "Display ark storage",
                        "Industrial equipment storage"
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Box-Type+Unit_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/Model+L+CUA+Box-Type+Unit+(Single+Vent+).png",
                    description : "Model L CUA Box-type Unit- 1 vent"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "Reasonable design, be applicable to the outdoor installation",
                        "Adopts 6 pole motor, the better to reduce noise",
                        "Three, in and out pipe installation stop valve, facilitate internal system is maintenance"
                    ],
                    useCase: [
                        "Apply to all kinds of high temperature of the cold storage",
                        "Display ark storage",
                        "Industrial equipment storage"
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Box-Type+Unit_watermark.pdf"
               }
            },
            {
               avatar: {
                    url: "https://d32u2qmsgdr7i7.cloudfront.net/condensingUnit/Model+L+CUA+Box-Type+Unit(Double+Vent+).png",
                    description : "Model L CUA Box-type Unit- 2 vents"
               },
               details: {
                    imageUrls: [],
                    features: [
                        "Reasonable design, be applicable to the outdoor installation",
                        "Adopts 6 pole motor, the better to reduce noise",
                        "Three, in and out pipe installation stop valve, facilitate internal system is maintenance"
                    ],
                    useCase: [
                        "Apply to all kinds of high temperature of the cold storage",
                        "Display ark storage",
                        "Industrial equipment storage"
                    ],
                    parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Box-Type+Unit_watermark.pdf"
               }
            }
        ]
    },
    {
            title: "Air Cooled Chiller",
            products: [
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooledChiller/Air+Cooled+Screw+Chiller+Unit.jpeg",
                        description : "Air Cooled Screw Chiller Unit"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Cooling conditions: chilled water inlet / outlet temperature: 12 degree /7 degree; underground water temperature of 18 degrees /29 degree, well water temperature range: 15 degrees -33 degrees",
                            "Compressor: the use of the international brand semi closed 5:6 asymmetric double screw type heat pump special type compressor",
                            "Heat exchanger: evaporator and condenser are efficient shell and tube heat exchanger, using imported plate material",
                            "Control system: using SIEMENS control system, advanced PLC control technology, can accept the programming, perfect communication function",
                            "The electrical system: SIEMENS, Schneider and other national brands of electrical components, waterproof type control cabinet",
                            "Man-machine interface: Chinese-English man-machine interface, touch type liquid crystal display operation, simple procedures, one key to start, automatic operation",
                            "Chiller maintenance: chiller  adopts integral design, compact structure, simple, strong corrosion resistance, with waterproof control cabinet, to ensure that the unit can be a long time in the outdoor, not easy to fade, corrosion",
                            "The choice of environmental protection type R407C or R134a refrigerant"
                        ],
                        useCase: ["Power stations", "Chemical plants", "Food production facilities", "And many more…"],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Air+Cooled+Screw+Chiller+Unit_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooledChiller/Air+cooled+water+Chiller.jpeg",
                        description : "Air Cooled Water Chiller"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Compressor: Vortex type compressor, adopt international brand fully enclosed vortex type maintenance free compressor",
                            "Heat exchanger: 5HP or bigger model, use high efficiency shell and tube evaporator and condenser",
                            "Control system: our own control system, simple operation, accurate control",
                            "Man-machine interface: the use of well-known brand controller and control interface, digital display, one key operation",
                            "Water tank:  adopt the overall design and manufacture, the built-in standard high efficiency centrifugal pipeline pump, 304 stainless steel storage tank",
                            "In order to adapt to the different requirements of all walks of life, the use of different series of scroll compressor, refrigeration water temperature from 15 degrees to -35 degrees, sophisticated technology to ensure the stability of the work under the extreme conditions",
                            "The choice of environmental protection type R407C or R134a refrigerant",
                            "Can use explosion proof fan motor",
                        ],
                        useCase: [
                            "Plastics industry",
                            "Plating solutions in electroplating industry",
                            "Electronic industry",
                            "Chemical industry",
                            "Pharmaceutical industry",
                            "Food industry, fresh-keeping industry",
                            "Jewelry processing, leather, aquaculture",
                            "And many more..."
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Air+Cooled+Water+Chiller+Unit_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/airCooledChiller/Screw+Strong+Air+Cooled+Chiller.jpeg",
                        description : "Horizontal Air Discharge Chiller"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Compressor: Vortex type compressor, adopt international brand fully enclosed vortex type maintenance free compressor",
                            "Heat exchanger: 5HP or bigger model, use high efficiency shell and tube evaporator and condenser",
                            "Control system: our own control system, simple operation, accurate control",
                            "Man-machine interface: the use of well-known brand controller and control interface, digital display, one key operation",
                            "Water tank:  adopt the overall design and manufacture, the built-in standard high efficiency centrifugal pipeline pump, 304 stainless steel storage tank",
                            "In order to adapt to the different requirements of all walks of life, the use of different series of scroll compressor, refrigeration water temperature from 15 degrees to -35 degrees, sophisticated technology to ensure the stability of the work under the extreme conditions",
                            "The choice of environmental protection type R407C or R134a refrigerant",
                        ],
                        useCase: [
                            "Plastics industry",
                            "Plating solutions in electroplating industry",
                            "Electronic industry",
                            "Chemical industry",
                            "Pharmaceutical industry",
                            "Food industry, fresh-keeping industry",
                            "Jewelry processing, leather, aquaculture",
                            "And many more..."
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Horizontal+Air+Discharge+Chiller_watermark.pdf"
                   }
                },
            ]
    },
    {
            title: "Water Cooled Chiller",
            products: [
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/waterCooledChiller/Centrifugal+Water+Cooled+Water+Chiller.jpeg",
                        description : "Centrifugal Water Cooled Water Chiller"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "R134a refrigerant is used. R134a is HFC working fluid, which is an internationally recognized environmentally friendly refrigerant",
                            "R134a is a positive pressure refrigerant, no need for exhaust, and the ventilation design of the machine room is simple",
                            "Use Hanbell two-stage centrifugal compressor",
                            "Use high-efficiency falling film type (spray type) evaporator",
                            "The unit built-in oil cooler, cooling with refrigerant, no maintenance",
                            "Use an independent oil return system (injection pump to return oil), and ensure oil return in time under any load",
                            "The oil pump is built in the compressor oil tank of the unit, so the oil pump has no leakage",
                            "The unit adopts a fixed orifice plate throttling device and has no moving parts. Under variable load and variable working conditions, the refrigerant flow can be adjusted in time without hysteresis to ensure stable operation of the unit",
                            "The unit-specific microcomputer control system uses the English screen to clearly display the various operating data, and the operation is extremely convenient",
                        ],
                        useCase: [
                            "Industrial Processes: Water chillers are extensively used in industrial processes that generate heat. They can cool equipment, machinery, and manufacturing processes to maintain optimal operating temperatures. Industries such as plastics, pharmaceuticals, food and beverage, and chemical manufacturing often rely on water-cooled chillers for process cooling",
                            "Air Conditioning: Large-scale air conditioning systems, particularly in commercial buildings and shopping malls, utilize water-cooled water chillers. These chillers cool the water, which is then circulated to air handling units (AHUs) or fan coil units (FCUs) to provide chilled air for cooling indoor spaces",
                            "Data Centers: Data centers generate significant amounts of heat due to the operation of servers and IT equipment. Water-cooled chillers help remove this heat efficiently. They provide cooling for the data center's computer room air conditioning (CRAC) units, ensuring stable and optimal operating temperatures for the servers",
                            "Medical Facilities: Hospitals, laboratories, and research facilities often require precise and reliable cooling systems. Water-cooled water chillers are used to cool medical imaging equipment (such as MRI and CT scanners), laboratory equipment, and other critical medical devices",
                            "Power Generation: Power plants and energy facilities require cooling for various processes. Water-cooled chillers can provide cooling for power generation equipment, including generators, transformers, and turbines, ensuring their efficient and reliable operation",
                            "High-rise Buildings: Tall buildings with multiple floors often employ water-cooled chillers for their air conditioning needs. These chillers can handle the cooling demands of large-scale HVAC systems in high-rise structures",
                            "Food and Beverage Industry: Water chillers are crucial in food and beverage processing, where temperature control is critical. They help maintain consistent cooling for food storage, production lines, and fermentation processes in breweries, wineries, and food processing plants",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Centrifugal+Water-Cooled+Water+Chiller_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/waterCooledChiller/Outdoor+water+cooled+chiller.jpeg",
                        description : "Outdoor Water Cooled Screw Chiller"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Compressor: adopt international brand semi closed 5:6 non symmetrical double screw type air cooled special type compressor",
                            "Heat exchanger: evaporator use high efficient shell and tube heat exchanger, condenser use high efficiency copper pipe+hydrophilic aluminum fin; with high pressure, high heat transfer efficiency, maintenance free, long service life, etc",
                            "Control system: using SIEMENS control system, advanced PLC control technology, can accept the programming, perfect communication function",
                            "The electrical system: SIEMENS, Schneider and other national brands of electrical components, waterproof type control cabinet",
                            "Man-machine interface: Chinese and  English man-machine interface, touch type liquid crystal display operation, simple procedures, one key to start, automatic operation",
                            "Chiller maintenance: chiller adopts integral design, compact structure, simple, strong corrosion resistance, with waterproof control cabinet, to ensure that the unit can be placed outside the long-term is not easy to fade, corrosion",
                            "The choice of environmental protection type R407C or R134a refrigerant",
                        ],
                        useCase: [
                            "Industrial Processes: Water chillers are extensively used in industrial processes that generate heat. They can cool equipment, machinery, and manufacturing processes to maintain optimal operating temperatures. Industries such as plastics, pharmaceuticals, food and beverage, and chemical manufacturing often rely on water-cooled chillers for process cooling",
                            "Air Conditioning: Large-scale air conditioning systems, particularly in commercial buildings and shopping malls, utilize water-cooled water chillers. These chillers cool the water, which is then circulated to air handling units (AHUs) or fan coil units (FCUs) to provide chilled air for cooling indoor spaces",
                            "Data Centers: Data centers generate significant amounts of heat due to the operation of servers and IT equipment. Water-cooled chillers help remove this heat efficiently. They provide cooling for the data center's computer room air conditioning (CRAC) units, ensuring stable and optimal operating temperatures for the servers",
                            "Medical Facilities: Hospitals, laboratories, and research facilities often require precise and reliable cooling systems. Water-cooled water chillers are used to cool medical imaging equipment (such as MRI and CT scanners), laboratory equipment, and other critical medical devices",
                            "Power Generation: Power plants and energy facilities require cooling for various processes. Water-cooled chillers can provide cooling for power generation equipment, including generators, transformers, and turbines, ensuring their efficient and reliable operation",
                            "High-rise Buildings: Tall buildings with multiple floors often employ water-cooled chillers for their air conditioning needs. These chillers can handle the cooling demands of large-scale HVAC systems in high-rise structures",
                            "Food and Beverage Industry: Water chillers are crucial in food and beverage processing, where temperature control is critical. They help maintain consistent cooling for food storage, production lines, and fermentation processes in breweries, wineries, and food processing plants",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Outdoor+Water+Cooled+Screw+Chiller_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/waterCooledChiller/Water+cooled+twin+screw+chiller.jpeg",
                        description : "Water Cooled Twin Screw Chiller"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "World famous brand new type and high-efficient screw-rotor compressor, with efficiency 20%~30% higher than those common ones. It has obtained several patents of Europe and America countries and passed the international quality certification of ISO9001",
                            "High efficiency 5-to-6 rotor profile patent",
                            "Capacity control apply to four-step (100%-75%-50%-25%),three-step (100%-66%-33%) and no step control system",
                            "With 11 bearings and a balance piston against axial thrust. This extends longer bearings life 2.5 to 3.5 times than traditional design",
                            "Designed with efficient silicon steel and special slots , and equipped with the inside and outside all around design to cool the flow channel so to ensure maximum efficiency of the compressor motor under any load",
                            "Built-in oil pressing system ensure compressor maintaining the optimal lubricity without oil pump",
                            "High efficiency double filtration oil separator ensures maximum heat exchanger",
                            "Efficient, low noise and small vibration",
                            "Various options of refrigerants, R22, environment-friendly R134A, R407C etc",
                            "All-wave compressor volume ratio(Vi=2.2/2.6/3.0/3.5) for selection to avoid the extra energy consumption caused by over or under compression",
                        ],
                        useCase: [
                            "Industrial Processes: Water chillers are extensively used in industrial processes that generate heat. They can cool equipment, machinery, and manufacturing processes to maintain optimal operating temperatures. Industries such as plastics, pharmaceuticals, food and beverage, and chemical manufacturing often rely on water-cooled chillers for process cooling",
                            "Air Conditioning: Large-scale air conditioning systems, particularly in commercial buildings and shopping malls, utilize water-cooled water chillers. These chillers cool the water, which is then circulated to air handling units (AHUs) or fan coil units (FCUs) to provide chilled air for cooling indoor spaces",
                            "Data Centers: Data centers generate significant amounts of heat due to the operation of servers and IT equipment. Water-cooled chillers help remove this heat efficiently. They provide cooling for the data center's computer room air conditioning (CRAC) units, ensuring stable and optimal operating temperatures for the servers",
                            "Medical Facilities: Hospitals, laboratories, and research facilities often require precise and reliable cooling systems. Water-cooled water chillers are used to cool medical imaging equipment (such as MRI and CT scanners), laboratory equipment, and other critical medical devices",
                            "Power Generation: Power plants and energy facilities require cooling for various processes. Water-cooled chillers can provide cooling for power generation equipment, including generators, transformers, and turbines, ensuring their efficient and reliable operation",
                            "High-rise Buildings: Tall buildings with multiple floors often employ water-cooled chillers for their air conditioning needs. These chillers can handle the cooling demands of large-scale HVAC systems in high-rise structures",
                            "Food and Beverage Industry: Water chillers are crucial in food and beverage processing, where temperature control is critical. They help maintain consistent cooling for food storage, production lines, and fermentation processes in breweries, wineries, and food processing plants",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Outdoor+Water+Cooled+Screw+Chiller_watermark.pdf"
                   }
                },
            ]
    },
    {
            title: "Unit Cooler",
            products: [
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/unitCooler/Floor+standing+dry+cooler.png",
                        description : "Floor Standing Dry Cooler"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Unique floating coil design avoids leakage caused by thermal expansion and contraction",
                            "Staggered fin pattern design and corrugated fin enhance air flow turbulence",
                            "Inner-grooved copper tube increase heat transfer area and enhance heat transfer",
                            "Optimized flow pass design ensure fully heat exchange with refrigerant",
                            "Equipped with 2nd generation owl-wing fan motor as standard. It features with low noise level, large volume flow and low energy consumption. Ready for dual speed connection",
                            "Outdoor type powder coated casing made of galvanized sheet metal and stainless steel bolts provide a high resistance to corrosion",
                            "EC variable speed fan motor, to save great energy",
                            "ZAplus/AxiTop for greater volume flow and extreme silence",
                            "Adiabatic system significantly reduce energy consumption in summer",
                            "Epoxy coated or hydrophilic aluminum fin suitable for coastal or high corrosion area",
                            "Clean stainless steel tube and aluminum fin coil for cooling deionized water",
                        ],
                        useCase: [
                            "Process engineering",
                            "Heat / power plant",
                            "Power generation",
                            "Chemical industry",
                            "Air conditioning chiller",
                            "Heat transformer",
                            "Smelting",
                            "And many more..."
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DRY+COOLER+SHSL-D3+SERIES_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/unitCooler/V-shaped+fluid+cooler.png",
                        description : "V-shaped Fluid Cooler"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Unique floating coil design avoids leakage caused by thermal expansion and contraction",
                            "Staggered fin pattern design and corrugated fin enhance air flow turbulence",
                            "Inner-grooved copper tube increase heat transfer area and enhance heat transfer",
                            "Optimized flow pass design ensure fully heat exchange with refrigerant",
                            "Equipped with 2nd generation owl-wing fan motor as standard. It features with low noise level, large volume flow and low energy consumption. Ready for dual speed connection",
                            "Outdoor type powder coated casing made of galvanized sheet metal and stainless steel bolts provide a high resistance to corrosion",
                            "EC variable speed fan motor, to save great energy",
                            "ZAplus/AxiTop for greater volume flow and extreme silence",
                            "Adiabatic system significantly reduce energy consumption in summer",
                            "Epoxy coated or hydrophilic aluminum fin suitable for coastal or high corrosion area",
                            "Clean stainless steel tube and aluminum fin coil for cooling deionized water",
                        ],
                        useCase: [
                            "Process engineering",
                            "Heat / power plant",
                            "Power generation",
                            "Chemical industry",
                            "Air conditioning chiller",
                            "Heat transformer",
                            "Smelting",
                            "And many more..."
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/DRY+COOLER+SHSL-D2+SERIES_watermark.pdf"
                   }
                },
            ]
    },
    {
            title: "Tube-fin Heat Exchanger",
            products: [
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Air+To+Air+Heat+Exchanger.jpeg",
                        description : "Air to Air Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Air to Air Heat Exchanger can be used in air compressor",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Copper+Tube+Aluminum+Fin+Heat+Exchanger.jpeg",
                        description : "Copper Tube Aluminum Fin Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Copper tube aluminum fin heat exchanger can be used in air conditioner, cold room, freezer, refrigerator and other area",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Copper+Tube+Copper+Fin+Heat+Exchanger.jpeg",
                        description : "Copper Tube Cooper Fin Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Copper tube copper fin heat exchanger can be used in marine ac and some an-ti corrosion area"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Stainless+Steel+Tube+Heat+Exchanger.jpeg",
                        description : "Stainless Steel Tube Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Stainless steel tube heat exchanger can be used in food industry and medical area"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Tube-fin+heat+exchanger.jpeg",
                        description : "Tube-fin Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Tube fin heat exchanger can be used in air conditioner, cold room, freezer, refrigerator and other area"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/tubeFinHeatExchanger/Water+Cooled+Heat+Exchanger.jpeg",
                        description : "Water Cooled Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Fin: flat or corrugated aluminum foils; hydrophilic or bare fin",
                            "Tube: copper pipe",
                            "Φ7mm, φ7.94mm, φ9.52mm ,φ12.75mm, φ15.88 of tube O.D. available",
                            "Customized service is available according to customer's drawings to meet end user's specified requirements",
                            "Materials of fin and tube per customer's need, it could be aluminum, copper or stainless steel",
                            "Leak test: 2.5Mpa water test",
                            "Producing process: tube bend, press fins, expand tube, welding, leak test, dry, inspect, pack",
                        ],
                        useCase: [
                            "Water cooled Heat Exchanger can be used in  some water system"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Tube-fin+Heat+Exchanger_watermark.pdf"
                   }
                },
            ]
    },
    {
            title: "Plate Heat Exchanger",
            products: [
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/plateHeatExchanger/Brazed+Plate+Heat+Exchanger.jpeg",
                        description : "Brazed Plate Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Advanced design soft and calculation soft in the world",
                            "High efficiency of heat exchange",
                            "Under the same heat transfer coefficient, the resistance losses of the product can be controlled within 1/3 scope as tubular heat exchanger",
                            "The ripple type plate aims at deep membrane heat conduction coefficient, and forms a special channel",
                            "liquid can bring the strong turbulence in the so low flow speed, and the rotational flow arises in the plate surface in order to shorten the time that the liquid rests on the surface, and avoid the dirty with self-purification",
                            "ISO9000 certified. The quality of the product is reliable according to the request of the ISO9000 in design, purchase, production and inspection",
                        ],
                        useCase: [
                            "Plate type heat exchangers are widely used in the heat exchange industry filed such as the ship, oil field, chemical industry, power industry, engineering industry, refrigeration air-condition, heat supply project and etc"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Plate+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/plateHeatExchanger/Gasketed+plate+heat+exchanger.jpeg",
                        description : "Gasket Plate Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Advanced design soft and calculation soft in the world",
                            "High efficiency of heat exchange",
                            "Under the same heat transfer coefficient, the resistance losses of the product can be controlled within 1/3 scope as tubular heat exchanger",
                            "The ripple type plate aims at deep membrane heat conduction coefficient, and forms a special channel",
                            "liquid can bring the strong turbulence in the so low flow speed, and the rotational flow arises in the plate surface in order to shorten the time that the liquid rests on the surface, and avoid the dirty with self-purification",
                            "ISO9000 certified. The quality of the product is reliable according to the request of the ISO9000 in design, purchase, production and inspection",
                        ],
                        useCase: [
                            "Plate type heat exchangers are widely used in the heat exchange industry filed such as the ship, oil field, chemical industry, power industry, engineering industry, refrigeration air-condition, heat supply project and etc"
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Plate+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/plateHeatExchanger/Separable+Spiral+Plate+Heat+Exchanger.jpeg",
                        description : "Separable Spiral Plate Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Maximum heat transfer",
                            "Curving passage creates high turbulence",
                            "Natural scrubbing action eliminates deposits",
                            "Compact design reduces space requirements",
                            "The equipment is made by two rolled materials, and then form two uniform spiral channels",
                            "The implementation of a tangential structure in the shell control reduces local resistance by ensuring a uniform curvature in the spiral channel. This design minimizes significant changes in liquid flow within the equipment, resulting in overall low resistance. Consequently, it enables higher flow speeds, enhancing the heat-transfer capability",
                            "The total drag is small",
                            "High leak-proof quality",
                            "Customization is available based on the customers' specifications"
                        ],
                        useCase: [
                            "Widely used in chemical, petroleum, solvents, medicine, food, light industry, textile, metallurgy, steel rolling, coking and other industries",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Plate+Heat+Exchanger_watermark.pdf"
                   }
                },
                {
                   avatar: {
                        url: "https://d32u2qmsgdr7i7.cloudfront.net/plateHeatExchanger/Spiral+Plate+Heat+Exchanger.jpeg",
                        description : "Spiral Plate Heat Exchanger"
                   },
                   details: {
                        imageUrls: [],
                        features:[
                            "Maximum heat transfer",
                            "Curving passage creates high turbulence",
                            "Natural scrubbing action eliminates deposits",
                            "Compact design reduces space requirements",
                            "The implementation of a tangential structure in the shell control reduces local resistance by ensuring a uniform curvature in the spiral channel. This design minimizes significant changes in liquid flow within the equipment, resulting in overall low resistance. Consequently, it enables higher flow speeds, enhancing the heat-transfer capability",
                            "The total drag is small",
                            "High leak-proof quality",
                            "Customization is available based on the customers' specifications"
                        ],
                        useCase: [
                            "Widely used in chemical, petroleum, solvents, medicine, food, light industry, textile, metallurgy, steel rolling, coking and other industries",
                        ],
                        parametersURL: "https://d39yxz2s1w7zpf.cloudfront.net/Plate+Heat+Exchanger_watermark.pdf"
                   }
                },

            ]
    },
]

export {
    contents
};