/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

//SEO Related settings
const seo = {
    title: "Achacha LLC",
    description:
        "Achacha LLC is a professional supplier that specializes in refrigeration units and parts, with its headquarters located in the state of Wyoming in the United States of America. As a supplier in the refrigeration industry, Achacha LLC provides high-quality equipment and components to businesses and individuals who require refrigeration solutions for their operations.\n" +
        "\n" +
        "Achacha LLC's product line includes a wide range of refrigeration units, from small refrigerators to large industrial-sized units that can handle large amounts of goods. Additionally, Achacha LLC also offers parts and accessories for refrigeration equipment, ensuring that customers can easily find the components they need to maintain and repair their units.\n" +
        "\n" +
        "With a commitment to quality and reliability, Achacha LLC prides itself on offering exceptional customer service to ensure that clients receive the best possible experience when dealing with the business. Achacha LLC's team of knowledgeable professionals is dedicated to providing customers with expert advice and support throughout the purchasing process and beyond.\n" +
        "\n" +
        "In summary, Achacha LLC is a reputable and reliable supplier of refrigeration units and parts, serving businesses and individuals across the United States and beyond from its headquarters in Wyoming",
    og: {
        title: "Achacha LLC",
        type: "website",
        url: "http://www.achachallc.com/",
    },
};

export {
    seo,
};