/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

const image = [
    {
        url: "https://d3bhio3vamc6vp.cloudfront.net/carousel/homePageCarousel-dryCoolers.jpg",
        description : "Achacha Dry Coolers, used in server rooms, Bitcoin mining and cryptocurrency data centers, chemical manufacturing industry. Stay Cool with Achacha! Your Ultimate Industrial/Commercial Cooling Solution!"
    },
    {
        url: "https://d3bhio3vamc6vp.cloudfront.net/carousel/homePageCarousel-coldRoom.jpg",
        description : "Achacha Cold Room, Affordable Industrial/Commercial Cooling Equipment"
    },
]

export {
    image
};