/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React, { useState } from "react";
import {Card, CardMedia, CardActions, CardContent, Button, Typography} from "@mui/material";
import "./CardContainer.css";
import ProductDetailsModal from "../../components/productDetailsModal/ProductDetailsModal.js";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CardContainer = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
            <Card className='cardContainer'>
                <LazyLoadImage
                    component="img"
                    height={195}
                    src= {props.data.avatar.url}
                    alt= {props.data.avatar.description}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {props.data.avatar.description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant="contained" onClick={showModal}>View Details</Button>
                    <ProductDetailsModal
                        isModalOpen={isModalOpen}
                        handleCancel={handleCancel}
                        productName={props.data.avatar.description}
                        imageUrl={props.data.avatar.url}
                        parameterUrl={props.data.details.parametersURL}
                        features={props.data.details.features}
                        useCase={props.data.details.useCase}
                    />
                </CardActions>
            </Card>
    );
}

export default CardContainer;