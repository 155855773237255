/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import "./ProductsByCategories.css";
import CardContainer from "../card/CardContainer";
import { Col, Row } from 'antd';

const ProductsByCategories = (props) => {
    return (
        <div className="categoriesContainer">
            <h3 id={props.title.replaceAll(" ", "_")}>{props.title}</h3>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {props.data.map((product, index) => (
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8}}>
                        <CardContainer data={product} />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default ProductsByCategories;