/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import "./ProductsPageMainContent.css";
import {contents} from "../../data/ProductsContent";
import ProductsSider from "../../components/productsSider/ProductsSider";
import ProductsByCategories from "../../components/productsByCategories/ProductsByCategories";

const ProductsPageMainContent = (props) => {
    return (
        <div className="productsContentContainer">
            <ProductsSider/>
            <div className="productsByCategoriesContainer">
                {contents.map(content => {
                    return <ProductsByCategories data={content.products} title={content.title}/>
                })}
            </div>
        </div>
    );
}

export default ProductsPageMainContent;