/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from 'react';
import {MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.css";
import './Footer.css';
import {footerTheme} from "../../theme"

const Footer = () => {
    const theme = footerTheme;
    return (
        <MDBFooter bgColor={theme.body} className='text-center text-lg-start' style={{backgroundColor: theme.body, color: theme.text}}>
            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom align-items'>
                <div className='me-5 d-none d-lg-block'>
                    <span>Get connected with us on social networks:</span>
                </div>

                <div>
                    <MDBBtn
                        floating
                        className='m-1'
                        style={{backgroundColor: '#3b5998'}}
                        href='https://www.facebook.com/achacha.llc'
                        role='button'
                        target="_blank"
                    >
                        <MDBIcon fab icon='facebook-f'/>
                    </MDBBtn>

                    <MDBBtn
                        floating
                        className='m-1'
                        style={{backgroundColor: '#55acee'}}
                        href='https://twitter.com/AchachaLLC'
                        role='button'
                        target="_blank"
                    >
                        <MDBIcon fab icon='twitter'/>
                    </MDBBtn>

                    <MDBBtn
                        floating
                        className='m-1'
                        style={{backgroundColor: '#dd4b39'}}
                        href='https://www.youtube.com/@Achacha-11c/featured'
                        role='button'
                        target="_blank"
                    >
                        <MDBIcon fab icon='youtube'/>
                    </MDBBtn>
                </div>
            </section>

            <section className=''>
                <MDBContainer className='text-center text-md-start mt-5'>
                    <MDBRow className='mt-3'>
                        <MDBCol md="3" lg="4" xl="4" className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>
                                <MDBIcon icon="gem" className="me-3"/>
                                Achacha LLC
                            </h6>
                            <p>
                                Achacha LLC is a professional supplier that specializes in refrigeration units and
                                parts, with its headquarters located in the state of Wyoming in the United States of
                                America.
                            </p>
                        </MDBCol>

                        <MDBCol md="2" lg="2" xl="4" className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Dry Cooler | Radiator | Unit Cooler | Fluid Cooler
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Air Cooler | Cold Room Evaporator
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Condensing Unit
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Heat Exchanger | Condenser
                                </a>
                            </p>
                        </MDBCol>

                        <MDBCol md="4" lg="3" xl="4" className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                            <p>
                                <MDBIcon icon="home" className="me-2"/>
                                30 N Gould St, Sheridan WY, 82801 USA
                            </p>
                            <p>
                                <MDBIcon icon="envelope" className="me-3"/>
                                achachallc@gmail.com
                            </p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>

            <div className='text-center p-4' style={{backgroundColor: theme.body}}>
                © 2023 Copyright:&nbsp;
                <a className='text-reset fw-bold' href='https://www.achachallc.com/'>
                    Achachallc.com or Achacha LLC
                </a>
            </div>
        </MDBFooter>
    );
}

export default Footer;