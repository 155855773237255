/*
Copyright (C) 2013 Achacha LLC - All Rights Reserved
 */

import React from "react";
import { Modal, Row, Col, Button, Space } from 'antd';
import "./ProductDetailsModal.css";
import {CardMedia} from "@mui/material";

const ProductDetailsModal = (props) => {

    const handleMoreParametersBtn = () => {
        window.open(props.parameterUrl, '_blank', 'noreferrer');
    }

    const handleGetQuoteBtn = () => {
        window.open('https://www.achachallc.com/contact?productName=' + props.productName.replace(/\s+/g, '-'), '_blank', 'noreferrer');
    }

    const featuresItems = props.features.map((element, index) =>
        <li key={index}>{element}</li>
    );

    const useCaseItems = props.useCase.map((element, index) =>
        <li key={index}>{element}</li>
    );

    return (
        <div>
          <Modal title="PRODUCT DETAILS" open={props.isModalOpen} onCancel={props.handleCancel}
            footer={null} className="modalContainer" width={1000} >
            <Row className="modalImage">
              <Col span={24}>
                  <CardMedia
                      component="img"
                      image={props.imageUrl}
                      alt={props.productName}
                  />
              </Col>
            </Row>
            <Row>
              <Col span={24}><h6>Features</h6></Col>
            </Row>
            <Row>
                <Col span={24}>
                  <ul>{featuresItems}</ul>
                </Col>
            </Row>
            <Row>
              <Col span={24}><h6>Use Cases</h6></Col>
            </Row>
            <Row>
                <Col span={24}>
                  <ul>{useCaseItems}</ul>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                  <Button type="primary" shape="round" size="large" onClick={handleMoreParametersBtn}>
                    <i class="fa fa-external-link" aria-hidden="true"></i>&nbsp; More Product Parameters
                  </Button>
                </Col>
            </Row>
            <Row className="contactUsToday">
              <Col span={24}><h6>&#129321; CONTACT US TODAY &darr;&darr;&darr;</h6></Col>
            </Row>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Button type="primary" size="large" onClick={handleGetQuoteBtn} block danger>
                GET A QUOTE
              </Button>
            </Space>
          </Modal>
        </div>
      );
}

export default ProductDetailsModal;